import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import Img from 'gatsby-image';

const ProjectBanner = ({ image, bgColor, textDark, title, description }) => {
  return (
    <div className="d-flex mt-5 mt-lg-0 justify-content-center align-items-center" style={{ backgroundColor: bgColor || '#488BF8', height: '100vh' }}>
      <Container className={`py-5 px-3 mt-5 px-md-3 ${textDark ? 'text-dark' : 'text-white'}`}>
        <Row noGutters className="align-items-center">
          <Col xs="12" lg="6">
            <h2>{title}</h2>
            <h6>{description}</h6>
          </Col>
          <Col xs="12" md="9" lg="6">
            <Img fluid={image} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProjectBanner;
