import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ProjectBanner from '../../components/projectBanner';

const PortfolioHappyToSign = () => {
  const data = useStaticQuery(graphql`
    query {
      happyToSign: file(relativePath: { eq: "happyToSign.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      happyToSignWebsite: file(relativePath: { eq: "happyToSignWebsite.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      happyToSignPreparation: file(relativePath: { eq: "happyToSignPreparation.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      signing: file(relativePath: { eq: "signing.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      happyToSignTracking: file(relativePath: { eq: "happyToSignTracking.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      happyToSignWhiteLabel: file(relativePath: { eq: "happyToSignWhiteLabel.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const contentArray = [
    {
      title: 'Document Preparation',
      description: 'Preparing a document for signing is one of the key functions of HappyToSign. We had to provide a frictionless way for users to prepare the document for signature. UX played a great role in making this tedious process simple for users.',
      image: <Img className="headshot w-100 mx-auto d-block" fluid={data.happyToSignPreparation.childImageSharp.fluid} alt="" />,
    },
    {
      title: 'Signing Documents',
      description: 'Procuring legally binding e-signatures on documents is the objective of this web app. It made this process easy, transparent and secure. The stakeholders could easily review the documents that required to be signed and securely sign or raise objections.',
      image: <Img className="headshot w-100 mx-auto d-block" fluid={data.signing.childImageSharp.fluid} alt="" />,
    },
    {
      title: 'Tracking Status',
      description: 'Tracking is an essential feature for the creator of the document to know if the stakeholders have received the document, signed or raised objections and immediately take action. The app made it easy to keep track of the status of the document at every stage.',
      image: <Img className="headshot w-100 mx-auto d-block" fluid={data.happyToSignTracking.childImageSharp.fluid} alt="" />,
    },
    {
      title: 'Whitelabelling',
      description: 'Enterprise clients finds whitelabelling the app to match their brand, as a valuable feature. We built a whitelablelling feature for enterprise clients where they can customize logo, color, font and also even custom domain for the app.',
      image: <Img className="headshot w-100 mx-auto d-block" fluid={data.happyToSignWhiteLabel.childImageSharp.fluid} alt="" />,
    },
  ];

  return (
    <Layout pageInfo={{ pageName: 'happytosign' }} noLayoutbanner>
      <SEO title="Happy To Sign | Projects" />
      <ProjectBanner bgColor="#3597EC" title="Happy To Sign" description="Capture legally binding electronic signatures for sturdier official bonds in contracts and agreements" image={data.happyToSign.childImageSharp.fluid} />
      <TheProject data={data} />
      <Documentation data={data} />
      <Content contentArray={contentArray} />
    </Layout>
  );
};

export default PortfolioHappyToSign;

const TheProject = () => {
  return (
    <Container>
      <Row noGutters className="my-5 py-5">
        <Col xs={12} lg={4}>
          <h4 className="text-left font-weight-bolder">The Project</h4>
          <hr style={{ width: '100px' }} className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" />
        </Col>
        <Col xs={12} lg={8}>
          <p>HappyToSign is an e-signature solution allows clients to upload/create documents and capture legally binding e-signatures. A full fledged web app which allows users to upload documents in several formats, prepare the document by adding fields, send the document to stakeholders and track it’s status.</p>
        </Col>
      </Row>
    </Container>
  );
};

const Documentation = (props) => {
  return (
    <Container className="align-items-center d-flex flex-direction-column">
      <Row noGutters className="my-5 py-5">
        <Col xs={12}>
          <h4 className="text-left text-md-center font-weight-bolder">Website And Documentation</h4>
          <hr className="text-left text-md-center d-flex info-color mb-4 mt-0 d-inline-block ml-0 mr-auto ml-md-auto hr-styles" />
        </Col>

        <Col xs={12} className="w-100 justify-content-center d-flex">
          <p style={{ maxWidth: '800px' }} className="text-left text-lg-center">
            HappyToSign is a feature rich application, hence it was essential to present it’s wide array of features in a way it can be easily understood by prospective customers through the website.
          </p>
        </Col>
        <Col xs={12}>
          <Img className=" mx-auto d-block" style={{ width: '100%' }} fluid={props.data.happyToSignWebsite.childImageSharp.fluid} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

const Content = (props) => {
  return (
    <Container>
      {props.contentArray.map((item) => {
        return (
          <Row noGutters className="my-5 py-5 align-items-center d-flex">
            <Col xs={12} lg={4}>
              <h4 className="text-left font-weight-bolder">{item.title}</h4>
              <hr className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" />
              <p>{item.description}</p>
            </Col>
            <Col xs={12} lg={8}>
              {item.image}
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};
